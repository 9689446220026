console.group("inicializando o pack de imagens do app")



import '/var/www/apps/azr/app/frontend/images/brand/app_logo_full.png';

import '/var/www/apps/azr/app/frontend/images/brand/app_logo_full_white.png';

import '/var/www/apps/azr/app/frontend/images/brand/app_logo_full_white_150.png';

import '/var/www/apps/azr/app/frontend/images/brand/app_symbol.png';

import '/var/www/apps/azr/app/frontend/images/brand/app_text.png';

import '/var/www/apps/azr/app/frontend/images/brand/azr_metadata.png';

import '/var/www/apps/azr/app/frontend/images/brand/clientes (1).png';

import '/var/www/apps/azr/app/frontend/images/site/3.jpg';

import '/var/www/apps/azr/app/frontend/images/site/active-marker.png';

import '/var/www/apps/azr/app/frontend/images/site/arrow-down.png';

import '/var/www/apps/azr/app/frontend/images/site/arrow-right.png';

import '/var/www/apps/azr/app/frontend/images/site/btn-arrow-down.png';

import '/var/www/apps/azr/app/frontend/images/site/btn-blue-arrow-right.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/1.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/2.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/3.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/4.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/5.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/6.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/7.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/8.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes/9.png';

import '/var/www/apps/azr/app/frontend/images/site/clientes.png';

import '/var/www/apps/azr/app/frontend/images/site/cristiano-mello.jpg';

import '/var/www/apps/azr/app/frontend/images/site/denise-de-mello.jpg';

import '/var/www/apps/azr/app/frontend/images/site/divider-top.png';

import '/var/www/apps/azr/app/frontend/images/site/douglas.jpg';

import '/var/www/apps/azr/app/frontend/images/site/equipe.jpg';

import '/var/www/apps/azr/app/frontend/images/site/facebook.png';

import '/var/www/apps/azr/app/frontend/images/site/footer-background.jpg';

import '/var/www/apps/azr/app/frontend/images/site/form-background.jpg';

import '/var/www/apps/azr/app/frontend/images/site/form-image.jpg';

import '/var/www/apps/azr/app/frontend/images/site/gabriel.jpg';

import '/var/www/apps/azr/app/frontend/images/site/gallery-1.png';

import '/var/www/apps/azr/app/frontend/images/site/gallery-2.png';

import '/var/www/apps/azr/app/frontend/images/site/gallery-3.png';

import '/var/www/apps/azr/app/frontend/images/site/gallery-shadow.png';

import '/var/www/apps/azr/app/frontend/images/site/geziel.jpg';

import '/var/www/apps/azr/app/frontend/images/site/header-background.jpg';

import '/var/www/apps/azr/app/frontend/images/site/heart.png';

import '/var/www/apps/azr/app/frontend/images/site/icon4servicesazr.png';

import '/var/www/apps/azr/app/frontend/images/site/instagram.png';

import '/var/www/apps/azr/app/frontend/images/site/interiores.png';

import '/var/www/apps/azr/app/frontend/images/site/juntos_somos.jpg';

import '/var/www/apps/azr/app/frontend/images/site/larissa.jpg';

import '/var/www/apps/azr/app/frontend/images/site/luma.jpg';

import '/var/www/apps/azr/app/frontend/images/site/marcus.jpg';

import '/var/www/apps/azr/app/frontend/images/site/outros.png';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/1.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/10.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/2.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/3.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/4.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/5.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/6.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/7.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/8.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/9.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/gallery/gallery-1.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/gallery/gallery-2.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/gallery/gallery-3.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/gallery/gallery-4.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio/gallery/gallery-5.jpg';

import '/var/www/apps/azr/app/frontend/images/site/portfolio-header.jpg';

import '/var/www/apps/azr/app/frontend/images/site/predial.png';

import '/var/www/apps/azr/app/frontend/images/site/quote.png';

import '/var/www/apps/azr/app/frontend/images/site/section-divider.png';

import '/var/www/apps/azr/app/frontend/images/site/services-cta-background.jpg';

import '/var/www/apps/azr/app/frontend/images/site/services-cta.jpg';

import '/var/www/apps/azr/app/frontend/images/site/services-sketch.png';

import '/var/www/apps/azr/app/frontend/images/site/sobre-background.jpg';

import '/var/www/apps/azr/app/frontend/images/site/viabilidade.png';

import '/var/www/apps/azr/app/frontend/images/site/whatsapp.png';

import '/var/www/apps/azr/app/frontend/images/site/white-logo.png';


console.groupEnd();
