export default function WaiterAsyncViewProxy(_callback) {
    var self = null;
    var container = null;
    var req = null;

    var callback = {
        success: function(proxy) {
        },
        failure: function(proxy, error) {
        },
        prepare: function(proxy, info) {
            return info;
        },
        wait: function(proxy, info) {
            return true;
        }
    };

    this.getCallback = function() {
        return callback;
    };

    this.setCallback = function(c) {
        callback = c;
    };

    this.setContainer = function(c) {
        container = c;
    };

    this.getContainer = function() {
        return container;
    };

    this.prepare = function() {
        return callback.prepare(self, {});
    };

    this.execute = function(_info) {
        var info = !_info ? self.prepare() : _info;

        if (req != null)
            return true;

        req = $.ajax
        ({
            url: info.url,
            type: "GET",
            data: info.data,
            processData: true,
            success: function() {
                callback.success(self)
            },
            error: function(event) {
                callback.failure(self, event);
            },
            complete: function() {
                req = null;
            }
        });

        return true;
    };

    this.abort = function() {
        if (req != null) {
            req.abort();
            req = null;
        }
    };

    this.isRunning = function() {
        return req != null;
    };

    var __construct = function(that) {
        self = that;

        if (!checkIfEmpty(_callback))
            callback = _callback;
    }(this);
};